var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', {
    staticClass: "justify-content-center"
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "11"
    }
  }, [_c('b-card', [_c('form-filter-export', {
    attrs: {
      "type_export": ['excel']
    },
    on: {
      "onExport": _vm.print
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [!_vm.isSales ? _c('b-form-group', {
          staticClass: "mr-2",
          attrs: {
            "label": "Sales"
          }
        }, [_c('v-select', {
          attrs: {
            "options": _vm.salesOptions,
            "label": "text",
            "reduce": function reduce(option) {
              return option.value;
            }
          },
          model: {
            value: _vm.dataExport.sales_id,
            callback: function callback($$v) {
              _vm.$set(_vm.dataExport, "sales_id", $$v);
            },
            expression: "dataExport.sales_id"
          }
        })], 1) : _vm._e()];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }